<template>
  <div>
    <reseller-add></reseller-add>
  </div>
</template>

<script>
import ResellerAdd from "./../../components/resellers/add";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("resellers.add.title")
    };
  },
  components: {
    ResellerAdd
  }
};
</script>